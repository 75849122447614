import React from 'react'

import { Connections } from '../../components/views/connections/connections'
import { LayoutLoggedIn } from '../../components/views/layout'

const ConnectionsPage = () => (
   <LayoutLoggedIn>
      <Connections />
   </LayoutLoggedIn>
)

export default ConnectionsPage
