// extracted by mini-css-extract-plugin
export var addItemToggle = "connections-module--addItemToggle--2b34c";
export var coordinatesButton = "connections-module--coordinates-button--ddfc2";
export var deleteForm = "connections-module--delete-form--a6a35";
export var description = "connections-module--description--4e75e";
export var divider = "connections-module--divider--b2a2b";
export var form = "connections-module--form--7a20e";
export var icon = "connections-module--icon--fac32";
export var importDownloadExample = "connections-module--import-download-example--f82e3";
export var importForm = "connections-module--import-form--d51b3";
export var locationsWrapper = "connections-module--locationsWrapper--1ab8d";
export var toggle = "connections-module--toggle--41ad4";
export var toggleField = "connections-module--toggle-field--b3375";
export var toggleLabel = "connections-module--toggle-label--013de";
export var toggleLabelDisabled = "connections-module--toggle-label-disabled--6dd07";
export var warehouseDescription = "connections-module--warehouse-description--aaf6d";