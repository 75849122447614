// extracted by mini-css-extract-plugin
export var coordinatesButton = "locations-module--coordinates-button--d5943";
export var deleteForm = "locations-module--delete-form--4e0c4";
export var description = "locations-module--description--716a3";
export var divider = "locations-module--divider--4c425";
export var form = "locations-module--form--8bf96";
export var icon = "locations-module--icon--86133";
export var importDownloadExample = "locations-module--import-download-example--ca8b5";
export var importForm = "locations-module--import-form--80b44";
export var locationsWrapper = "locations-module--locationsWrapper--210ae";
export var toggle = "locations-module--toggle--341cb";
export var toggleField = "locations-module--toggle-field--92210";
export var toggleLabel = "locations-module--toggle-label--77308";
export var warehouseDescription = "locations-module--warehouse-description--1c81c";